import React from 'react'
import Home from '../pages/Home'
import Footer from './Footer'

const Layout = () => {
    return (
        <div className="p-8 md:p-0">
            <Home />
            <Footer />
        </div>
    )
}

export default Layout