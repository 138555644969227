import React from 'react'
import logo from '../resources/face.jpg'

const Home = () => {
    return (
        <div className="text-slate-600 body-font md:p-10 mb-10 md:mb-20">
            <div className="container flex md:flex-row flex-col items-center">
                <div className="lg:w-2/4 md:w-3/6 w-5/6">
                    <img className="object-cover object-center rounded" alt="hero" src={logo} />
                </div>
                <div className="lg:flex-grow md:w-2/3 flex flex-col md:items-start md:text-left items-center text-center md:ml-10 mt-10">
                    <h1 className="title-font sm:text-3xl text-2xl mb-4 font-medium text-slate-900">Director of Innovation, Research and Development at LPG SYSTEMS</h1>
                    <p className="mb-8 leading-relaxed">Innovation leader with profound experience and insight into R&I programmes focussed on skincare, beauty tech and healthcare applications.</p>
                    <p className="mb-8 leading-relaxed">Combining strong technical expertise in biophysics and biomedical engineering with effective management and cooperation, I strive to nurture future diagnostics, rejuvenation and well-being solutions. I am passionate about the interactions of physical/chemical agents with living tissues and the device/body interface - and about using their potential for treatments or wearables.</p>
                    <p className="mb-8 leading-relaxed">Fascinated by science and engineering but pragmatic and results-oriented, I particularly enjoy working with international and interdisciplinary groups of experts.</p>
                    <p className="mb-8 leading-relaxed">In a wider context, I look for ways to better develop and share intellectual potential through international and inter-sectoral researcher mobility, private-public cooperation and funding programmes.</p>
                    <div className="flex justify-center">
                        <div className="flex justify-center lg:justify-start items-center gap-4 mt-8 sm:mt-16">
                            <span className="text-slate-400 text-sm sm:text-base font-semibold tracking-widest uppercase">FIND MORE</span>
                            <span className="w-12 h-px bg-slate-200"></span>
                            <div className="flex gap-4">
                                <a href="https://www.linkedin.com/in/yegor-domanov/" target="_blank" className="text-slate-400 hover:text-blue-500 active:text-blue-800 transition duration-100" rel="noreferrer">
                                    <svg className="w-5 h-5" width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
