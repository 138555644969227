import React from 'react'

const Footer = () => {
    return (
        <div className="bg-white pt-4 sm:pt-10 lg:pt-12 ">
            <footer className="max-w-screen-2xl px-4 md:px-8 mx-auto ">
                <div className="text-slate-600 text-sm text-center py-8 border-t border-slate-400">© 2022 <a href='https://ruomi.io/' className='hover:underline hover:text-blue-700'>Ruomi ITS</a>. All rights reserved.</div>
            </footer>
        </div>
    )
}

export default Footer